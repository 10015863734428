import * as React from 'react'
import { Layout } from '../components/layout'
import * as styles from './salary-privacy.module.scss'

export default () => {
  return (
    <Layout>
      <section className={styles.text}>
        <h2>Privacy Policy</h2>
        <p>
          Vi sparar inte mer data om dig än vad som behövs för att kunna
          tillhandahålla och vidareutveckla våra tjänster. Vi delar inte med oss
          av din information till någon tredje part, förutom i de fall då det
          krävs för att tillhandahålla våra tjänster eller i de fall då vi är
          tvingade att göra så enligt gällande lagar.
        </p>
        <p>
          Information som sparas om dig inkluderar IP-adresser, anonymiserad
          användningsdata och all information som du själv för in i våra
          tjänster.
        </p>
        <p>
          I Lönekalkylatorn kan du själv föra in information om dina arbetstider
          och räkna ut din lön. För att ta bort informationen kan du ta bort
          ditt konto inuti appen. Notera att detta är permanent, och att vi inte
          kan återskapa kontot.
        </p>
      </section>
    </Layout>
  )
}
